<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotSupplierDevice' }">供应商品牌</el-breadcrumb-item>
          <el-breadcrumb-item>新增品牌</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备品牌" prop="equipmentBrand">
                <el-input v-model="form.equipmentBrand" placeholder="请输入设备品牌"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备型号" prop="equipmentBrandCode">
                <el-input v-model="form.equipmentBrandCode" placeholder="请输入设备型号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备类型" prop="deviceTypeName">
                <el-row :gutter="0">
                  <el-col :span="19">
                    <el-input v-model="form.deviceTypeName" disabled placeholder="请选择设备类型"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-button type="primary" @click="addDevice">选择</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供应商" prop="supplier">
                <el-row :gutter="0">
                  <el-col :span="19">
                    <el-input v-model="form.supplier" disabled placeholder="请选择供应商"></el-input>
                  </el-col>
                  <el-col :span="5">
                    <el-button type="primary" @click="addSupplier">选择</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="能力点表" prop="ability">
                <el-select v-model="form.ability" style="width:100%;" placeholder="请选择能力点表">
                  <el-option
                    v-for="item in abilityOrPointTable"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12" v-show="form.ability==0">
              <el-form-item label="文件选择">
                <el-row>
                  <el-col :span="19">
                    <el-input v-model="excelName" placeholder="请选择文件" disabled></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-upload
                      class="cancel-btn"
                      action=""
                      :auto-upload="false"
                      accept=".xlsx, .xls"
                      :show-file-list="false"
                      :on-change="handleChange">
                      <el-button type="primary">选择</el-button>
                    </el-upload>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col> -->
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotSupplierDevice')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <!-- 添加设备 -->
      <el-drawer
        title="添加设备"
        :with-header="false"
        :visible.sync="drawerDevice"
        direction="rtl"
        size="600px">
        <div class="drawer-title">添加设备</div>
        <div class="drawer-content table-tree">
          <div class="table">
            <el-table :data="tableDeviceData" height="700" v-loading="tableDeviceLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-radio-group v-model="deviceRadio" @change="checkDeviceChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="设备类型名称"></el-table-column>
              <el-table-column prop="deviceTypeCode" label="设备类型编码"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawerDeviceCancel=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="drawerDeviceOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <!-- 添加供应商 -->
      <el-drawer
        title="添加供应商"
        :with-header="false"
        :visible.sync="drawerSupplier"
        direction="rtl"
        size="600px">
        <div class="drawer-title">添加供应商</div>
        <div class="drawer-content table-tree">
          <div class="table">
            <el-table :data="tableSupplierData" height="700" v-loading="tableSupplierLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-radio-group v-model="supplierRadio" @change="checkSupplierChange(scope.row)">
                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                  </el-radio-group>
                </template>
              </el-table-column>
              <el-table-column prop="supplier" label="供应商名称"></el-table-column>
              <el-table-column prop="supplierCode" label="供应商编码"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawerSupplierCancel=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="drawerSupplierOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        deviceTypeName: '',
        supplier: ''
      },
      rules: {
        equipmentBrand:[{ required: true, message: '必填项', trigger: 'blur' }],
        equipmentBrandCode:[{ required: true, message: '必填项', trigger: 'blur' }],
        ability:[{ required: true, message: '必填项', trigger: 'blur' }],
        deviceTypeName:[{ required: true, message: '必填项', trigger: 'blur' }],
        supplier:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      disabled: false,
      allFileList: [],

      // 设备类型
      drawerDevice: false,
      deviceRadio: '',
      tableDeviceData: [],
      tableDeviceSelect: {},
      tableDeviceLoading: false,

      // 供应商
      drawerSupplier: false,
      supplierRadio: '',
      tableSupplierData: [],
      tableSupplierSelect: {},
      tableSupplierLoading: false,

      abilityOrPointTable: [
        { id:1, name:'设备' },
        { id:2, name:'网关' }
      ],
      excelName: null,
      fileRaw: null,
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // if(!this.excelName&&this.form.ability==0) {
          //   return this.$message.error('请选择文件')
          // }
          let formData = new FormData();
          formData.append("equipmentBrand", this.form.equipmentBrand);
          formData.append("equipmentBrandCode", this.form.equipmentBrandCode);
          formData.append("deviceTypeCode", this.form.deviceTypeCode);
          formData.append("supplierCode", this.form.supplierCode);
          formData.append("abilityOrPointTable", this.form.ability);
          // if(this.fileRaw) {
          //   formData.append("file", this.fileRaw);
          // }
         
          this.loading = true;
          this.$ajax.post("supplierDeviceCreate", formData).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/iotSupplierDevice");
          }).catch(err=>{
            this.loading = false;
          })
        }
      })
    },
    handleChange(e) {
      console.log(e)
      this.excelName = e.name
      this.fileRaw = e.raw
    },
    uploadChange(file, fileList) {
      this.allFileList = fileList;
      console.log(fileList);
    },
    handleRemove(file) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allFileList = this.allFileList.filter(checkAdult);

      console.log(this.allFileList);
    },

    // 设备类型
    loadDeviceTypeData() {
      this.tableDeviceLoading = true
      this.$ajax.post('iotDeviceQuery').then(res => {
        this.tableDeviceData = res.data
        this.tableDeviceLoading = false
      }).catch(err=>{
        this.tableDeviceLoading = false
      })
    },
    // 供应商信息
    loadSupplierData() {
      this.tableSupplierLoading = true
      this.$ajax.post('iotSupplierQuery').then(res => {
        this.tableSupplierData = res.data
        this.tableSupplierLoading = false
      }).catch(err=>{
        this.tableSupplierLoading = false
      })
    },

    addDevice() {
      this.loadDeviceTypeData()
      this.deviceRadio = ''
      this.drawerDevice = true
    },
    // 勾选信息数据
    checkDeviceChange(row) {
      this.deviceRadio = row.id
      this.tableDeviceSelect = row // 勾选
    },
    drawerDeviceOK() {
      this.form.deviceTypeCode = this.tableDeviceSelect.deviceTypeCode
      this.form.deviceTypeName = this.tableDeviceSelect.name
      this.drawerDevice = false
    },

    addSupplier() {
      this.loadSupplierData()
      this.supplierRadio = ''
      this.drawerSupplier = true
    },
    // 勾选信息数据
    checkSupplierChange(row) {
      this.supplierRadio = row.id
      this.tableSupplierSelect = row // 勾选
    },
    drawerSupplierOK() {
      this.form.supplierCode = this.tableSupplierSelect.supplierCode
      this.form.supplier = this.tableSupplierSelect.supplier
      this.drawerSupplier = false
    },
  },
  mounted() {
    
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
</style>